define('absolutecomedy/controllers/kingston/events', ['exports', 'ember-fetch/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    mixpanel: Ember.inject.service(),
    number_of_tickets: 2,

    actions: {
      selectTickets: function selectTickets(quantity) {
        this.set('number_of_tickets', quantity);
      },
      createCart: function createCart(sku) {
        var _this = this;

        var quantity = this.get('number_of_tickets');
        var event = this.get('model');

        var payload = {
          event: parseInt(event.id),
          boxoffice: parseInt(event.boxoffice),
          items: [{
            sku: parseInt(sku),
            quantity: parseInt(quantity)
          }]
        };

        (0, _ajax.default)('https://app.noqueue.ca/api/carts', {
          method: 'POST',
          body: JSON.stringify(payload)
        }).then(function (response) {
          _this.get('mixpanel').trackEvent('Created Cart', response);
          console.log(response);
          _this.transitionToRoute('ottawa.checkout', response.uid);
        }).catch(function (err) {
          //this.get('mixpanel').trackEvent('Cart Error', err);
        });
      }
    }

  });
});