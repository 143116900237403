define('absolutecomedy/routes/summer-ticket-bonanza', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    stripe: Ember.inject.service('stripev3'),
    mixpanel: Ember.inject.service(),

    beforeModel: function beforeModel() {
      return this.get('stripe').load();
      //this.get('mixpanel').trackEvent('Summer Promo Initiated');
    },
    setupController: function setupController(controller) {
      controller.send('updateTickets');
    }
  });
});