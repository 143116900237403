define('absolutecomedy/components/sticky-element/trigger', ['exports', 'ember-sticky-element/components/sticky-element/trigger'], function (exports, _trigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _trigger.default;
    }
  });
});