define('absolutecomedy/router', ['exports', 'absolutecomedy/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('ottawa', function () {
      this.route('dinner-and-show-package');

      this.route('menu', function () {
        this.route('entrees');
        this.route('sandwiches');
        this.route('appetizers');
        this.route('drinks');
      });
      this.route('qr-code');
      this.route('hire-a-comedian');
      this.route('fundraisers');
      this.route('gifts');
      this.route('checkout', { path: '/checkout/:uid' });
      this.route('pyac');
      this.route('amnesty-month');
      this.route('holiday');
      this.route('events', { path: '/events/:id' });
    });
    this.route('toronto', function () {
      this.route('menu', function () {
        this.route('entrees');
        this.route('sandwiches');
        this.route('appetizers');
        this.route('drinks');
      });
      this.route('hire-a-comedian');
      this.route('fundraisers');
      this.route('gifts');
      this.route('dinner-and-show-package');
      this.route('checkout', { path: '/checkout/:uid' });
      this.route('pyac');
      this.route('amnesty-month');
      this.route('events', { path: '/events/:id' });
      this.route('holiday');
      this.route('standup-comedy-class');
      this.route('event-grouping', { path: "/tommy-lama" });
      this.route('pro-am-night');
    });
    this.route('kingston', function () {
      this.route('menu', function () {
        this.route('entrees');
        this.route('sandwiches');
        this.route('appetizers');
        this.route('drinks');
      });
      this.route('hire-a-comedian');
      this.route('fundraisers');
      this.route('gifts');
      this.route('dinner-and-show-package');
      this.route('checkout', { path: '/checkout/:uid' });
      this.route('amnesty-month');
      this.route('holiday');
      this.route('events', { path: '/events/:id' });
    });

    this.route('not-found', { path: '/*path' });

    //this.route('summer-ticket-bonanza');
    this.route('checkout');
  });

  exports.default = Router;
});