define('absolutecomedy/controllers/kingston/index', ['exports', 'ember-fetch/ajax', 'moment'], function (exports, _ajax, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    calendar: [],
    events: [],
    lineups: [{
      type: 'lineup',
      title: 'Headliner Tommy Lama with MC Abdullah Usman',
      start: (0, _moment.default)(new Date(2021, 7, 18, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 7, 21, 23, 0)),
      className: ['cal_expired']
    }, {
      type: 'lineup',
      title: 'Headliner Andrew Grose with MC Andrew Oporto',
      start: (0, _moment.default)(new Date(2021, 7, 25, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 7, 28, 23, 0)),
      className: ['cal_expired']
    }, {
      type: 'lineup',
      title: 'Headliner Tom Calnan with MC Aaron Power',
      start: (0, _moment.default)(new Date(2021, 8, 1, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 8, 4, 23, 0)),
      className: ['cal_expired']
    }, {
      type: 'lineup',
      title: 'Headliner Evan Carter with MC Brian Stollery',
      start: (0, _moment.default)(new Date(2021, 8, 8, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 8, 11, 23, 0)),
      className: (0, _moment.default)(new Date(2021, 8, 11, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Brian Stollery with MC Dan Allaire',
      start: (0, _moment.default)(new Date(2021, 8, 15, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 8, 18, 23, 0)),
      comic_1: {
        name: 'Brian Stollery',
        src: 'BrianStollery.png',
        bio: 'Brian Stollery is a Calgary-based comedian who has been a fixture in Canadian comedy for over two decades. He provides unique and hilarious shows for corporate clients. Brian is known for his near obsessive attention to detail and willingness to customize his show specifically to the needs of any given client and audience. He has performed for a variety of international companies including Coca Cola, Honda and Nexen, in addition to regional clients such as the Alberta Medical Association and Investors Group. Exceptional feedback has made Brian Stollery one of Canada\u2019s most sought after corporate comedians.'
      },
      comic_2: {
        name: 'Dan Allaire',
        src: 'DanAllaire.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 8, 18, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Jen Grant with MC Julien Dionne',
      start: (0, _moment.default)(new Date(2021, 8, 22, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 8, 25, 23, 0)),
      comic_1: {
        name: 'Jen Grant',
        src: 'JenGrant.png',
        bio: 'Jen Grant is an award nominated stand up comedian as well as a dynamic key note speaker. Originally from Ottawa, Jen was honoured to be profiled in Ottawa Life Magazine as one of the top 25 People in the Capital. A story telling, observational style of comedian, Jen is both unique and relatable. She writes about life and she will have you laughing until you\u2019re crying. Funny tears. Jen is a clean comic who is adaptable to any setting and is an excellent choice for any corporate function. Her ability to weave hilarious jokes with funny audience interaction is extraordinary. As an accomplished comedian of 20 years, Jen has achieved virtually everything you can in the world of stand-up comedy in Canada. She has appeared on TV at every major comedy festival in the country including the Just for Laughs Festival, the Ha!ifax and the Winnipeg Comedy Festival. She can also be heard on CBC\u2018s \u201CThe Debaters\u201D and has her own TV special on The Comedy Network. One of her proudest achievements was when she performed for the Troops in the Middle East. Jen was the first Canadian to reach the finals of the prestigious Boston Comedy Competition and was listed as one of the top five funniest people in Toronto by Toronto Life Magazine. As a keynote speaker Jen is engaging, inspiring, entertaining and informative. The audience will leave with not only a message and tools to help inspire change in their lives but also with sore cheeks from laughing so hard. Now living just outside Ottawa, Jen has lived in New York City, Toronto and Vancouver. She is excited to have released her CD called \u201CNobody Likes Your Homemade Wine\u201D It is available on iTunes. It\u2019s really funny! You should buy it and listen to it. Or buy it for a friend who makes you homemade wine and you want them to get the hint. Other CDs \u2013 \u201CJokes That Kill\u201D as well as \u201CMy Jokes are up here\u201D Vol 1 and 2.'
      },
      comic_2: {
        name: 'Julien Dionne',
        src: 'JulienDionne.png',
        bio: 'Julien Dionne Bio (EN) Canadian Comedy Award nominated comedian and podcaster, Julien Dionne, has been touring Canada and parts of the US, since 2006. Originally from Scoudouc, New Brunswick, Julien now calls Wakefield(QC) home via Toronto \u2014 having spent 2 years in New York City cutting his teeth and honing his craft alongside some of the best comedians in the world. In 2018, Julien taped a \u201CJuste Pour Rire\u201D gala in French, in Edmonton, as part of \u201CLes Rendez-Vous De La Francophonie\u201D to be aired nationally, as well as two galas for \u201CCom\xE9dieHa!\u201D In Quebec City in both French and English. His comedy has also been heard on XM/Sirius Satellite and CBC Radio. Julien was a performer at JFL42 (Just for Laughs-Toronto),  The Hubcap Comedy Festival in Moncton, The Cornwall Comedy Festival,  The New York City Frigid Festival,  and every major comedy club from Victoria, British Columbia to Saint John\u2019s Newfoundland. Julien has also appeared in numerous television commercials and played a lead role in the CBC mini-series, Canada: The Story of Us that aired in 2017.'
      },
      className: (0, _moment.default)(new Date(2021, 8, 25, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Kyle Brownrigg with MC Ernie Vicente',
      start: (0, _moment.default)(new Date(2021, 8, 29, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 9, 2, 23, 0)),
      comic_1: {
        name: 'Kyle Brownrigg',
        src: 'KyleBrownrigg.png',
        bio: 'Kyle Brownrigg is an undeniable presence in Canadian comedy. Originally from Ottawa, Kyle now makes his home in Toronto where he is a local and touring comic. In June 2019 he was named \'Best Breakout Artist\' at the Canadian Comedy Awards and was a finalist in SiriusXM\'s Top Comic competition, the search for Canada\'s next top comic, one of JFL42\'s New Faces, and was selected as one of Canada\u2019s Homegrown Comics for Just for Laughs. In his short career Kyle has done tapings for Comedy Network, CTV, and CBC Gem as part of Just for Laughs, CBC\u2019s Halifax Comedy Festival, Laugh Out Loud, and The Debaters. He is certainly someone you don\u2019t want to miss.'
      },
      comic_2: {
        name: 'Ernie Vicente',
        src: 'ErnieVicente.png',
        bio: 'Ernie Vicente is a stand-up comedian from Toronto, ON. With his surreal observations and quick one-liners, Ernie has been entertaining audiences since 2008, starting at the world famous Yuk Yuk\'s comedy club in Toronto. Since then he has performed in clubs such as Absolute Comedy, The Rivoli, The Second City, Yuk Yuk\'s, The Corner Comedy Club, Montreal\u2019s ComedyWorks, and more recently, various comedy clubs in Los Angeles, Laughs Unlimited in Sacramento, Catch a Rising Star (comedy clubs) in Reno, and Levity Live in New York City. In 2009, Ernie was a top 5 finalist in the Russell Peters\u2019s BFF comedy contest. Then in 2010, he performed at Kenny Robinson (comedian)\u2019s prestigious Nubian Disciples Show at Yuk Yuk\'s Toronto. Ernie is looking forward to performing in front of audiences all across Canada, and then the world. Ernie graduated from Sheridan College in 2006 with a diploma in Business administration.'
      },
      className: (0, _moment.default)(new Date(2021, 9, 2, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Jim McNally with MC Clifford Myers',
      start: (0, _moment.default)(new Date(2021, 9, 6, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 9, 9, 23, 0)),
      comic_1: {
        name: 'Jim McNally',
        src: 'JimMcNally.png',
        bio: 'Jim McNally is a comedian with an irreverent outlook that blends big city wit with small town cheekiness. His Irish, story-telling roots shine through in his performances which weave observations of the everyday into stories from his own life to highlight the absurdities of the world we live in.'
      },
      comic_2: {
        name: 'Clifford Myers',
        src: 'CliffordMyers.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 9, 9, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Dylan Rhymer with MC Ryan Maglunob',
      start: (0, _moment.default)(new Date(2021, 9, 13, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 9, 16, 23, 0)),
      comic_1: {
        name: 'Dylan Rhymer',
        src: 'DylanRhymer.png',
        bio: 'Edgy, charming and hilarious, Dylan Rhymer is truly in a class of his own. For 20 years Dylan Rhymer - a headline act from Canada - has delighted and challenged audiences all over the world with his intelligent, provocative and fast-paced brand of stand-up comedy. He explores social issues, philosophy and current events through the lens of his own insecurities and stupid shortcomings in a way that everyone can relate to. \u200B He has a sharp and imaginative style that thrills audiences while encouraging them to think. Dylan also created and performed the character Dick Hackney (an obnoxious 80\'s stand-up comic), for the hugely popular video game Scarface: the World is Yours (Radical Games). Players can blow his head off with very large guns on a regular basis!.'
      },
      comic_2: {
        name: 'Ryan Maglunob',
        src: 'RyanMaglunob.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 9, 16, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Nick Reynoldson with MC Dena Jackson',
      start: (0, _moment.default)(new Date(2021, 9, 20, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 9, 23, 23, 0)),
      comic_1: {
        name: 'Nick Reynoldson',
        src: 'NickReynoldson.png',
        bio: 'Hailing from Scarborough, Ontario, Nick Reynoldson has a unique view of the world. Sharp, smart and energetic, this young comic is quickly on the rise. A Runner Up at SiriusXM\u2019s Top Comic Competition, Nick has headlined  and appeared in major festivals including Just For Laughs, Kevin Hart\u2019s LOL Network, JFL42, the Winnipeg Comedy Festival, and NXNE.  He\u2019s been featured on MTV, MUCH, CBC and multiple national commercials.'
      },
      comic_2: {
        name: 'Dena Jackson',
        src: 'DenaJackson.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 9, 23, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliners Noor Kidwai & Jason Allen',
      start: (0, _moment.default)(new Date(2021, 9, 27, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 9, 30, 23, 0)),
      comic_1: {
        name: 'Noor Kidwai',
        src: 'NoorKidwai.png',
        bio: null
      },
      comic_2: {
        name: 'Jason Allen',
        src: 'JasonAllen.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 9, 30, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Darryl Purvis with MC Monty Scott',
      start: (0, _moment.default)(new Date(2021, 10, 3, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 10, 6, 23, 0)),
      comic_1: {
        name: 'Darryl Purvis',
        src: 'DarrylPurvis.png',
        bio: null
      },
      comic_2: {
        name: 'Monty Scott',
        src: 'MontyScott.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 10, 6, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Todd Van Allen with MC Abdullah Usman',
      start: (0, _moment.default)(new Date(2021, 10, 10, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 10, 13, 23, 0)),
      comic_1: {
        name: 'Todd Van Allen',
        src: 'ToddVanAllen.png',
        bio: 'Born in small-town Ontario and moved to Toronto, Todd Van Allen traded life from the provincial capital to the nation\u2019s capital. Based in Ottawa, Canada, Todd\u2019s comedy is sourced locally and delivered globally through his \u201CComedy Above the Pub\u201D podcast now in its 21st season, and his ongoing comedy roast show \u201CWrite Em Up!\u201D, now celebrating its fifth year, is now available online. Todd\u2019s performance grapples with relationships, society aging and trying to take a picture on his stupid phone. With his second album \u201CButterflies\u201D debuted #1 on iTunes comedy charts, it\u2019s no wonder he\u2019s headlined across North America and Europe, having performed at the #JFL42 Festival five times as well as the Melbourne Comedy Festival and the Edinburgh Festival. Trying his best to be a middle-aged woke man, one thing is certain: Todd\u2019s is the best voice you\u2019re going to hear tonight.'
      },
      comic_2: {
        name: 'Abdullah Usman',
        src: 'AbdullahUsman.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 10, 13, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Alex Wood with MC Sean Carson',
      start: (0, _moment.default)(new Date(2021, 10, 17, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 10, 20, 23, 0)),
      comic_1: {
        name: 'Alex Wood',
        src: 'AlexWood.png',
        bio: null
      },
      comic_2: {
        name: 'Sean Carson',
        src: 'SeanCarson.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 10, 20, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Marc Anthony Sinagoga with MC Matt Watson',
      start: (0, _moment.default)(new Date(2021, 10, 24, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 10, 27, 23, 0)),
      comic_1: {
        name: 'Marc Anthony Sinagoga',
        src: 'Marc-AnthonySinagoga.png',
        bio: null
      },
      comic_2: {
        name: 'Matt Watson',
        src: 'MattWatson.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 10, 27, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Geoff MacKay with MC Denis Grignon',
      start: (0, _moment.default)(new Date(2021, 11, 1, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 11, 4, 23, 0)),
      comic_1: {
        name: 'Geoff MacKay',
        src: 'GeoffMacKay.png',
        bio: null
      },
      comic_2: {
        name: 'Denis Grignon',
        src: 'DenisGrignon.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 11, 4, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Monty Scott with MC Joey Elias',
      start: (0, _moment.default)(new Date(2021, 11, 8, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 11, 11, 23, 0)),
      comic_1: {
        name: 'Monty Scott',
        src: 'MontyScott.png',
        bio: 'Monty Scott was born and raised in Scarborough, Ontario, and manages to combine an urban practicality with an intellectual and often silly bent. Always smart and original, Monty Scott has performed in theatres and clubs throughout North America and been featured on the Comedy Network, MTV and SiriusXM Radio.'
      },
      comic_2: {
        name: 'Joey Elias',
        src: 'JoeyElias.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 11, 11, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner DJ Demers with MC Marc Aaron Power',
      start: (0, _moment.default)(new Date(2021, 11, 15, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 11, 18, 23, 0)),
      comic_1: {
        name: 'DJ Demers',
        src: 'DJDemers.png',
        bio: null
      },
      comic_2: {
        name: 'Aaron Power',
        src: 'AaronPower.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 11, 18, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'The Best of Kingston with MC Kris Kingsbury',
      start: (0, _moment.default)(new Date(2021, 11, 22, 0, 0)),
      end: (0, _moment.default)(new Date(2021, 11, 25, 23, 0)),
      comic_1: {
        name: 'Kris Kingsbury',
        src: 'KrisKingsbury.png',
        bio: null
      },
      comic_2: {
        name: 'Ryan Maglunob',
        src: 'RyanMaglunob.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2021, 11, 25, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Jason Blanchard with MC Lianne Mauladin',
      start: (0, _moment.default)(new Date(2021, 11, 29, 0, 0)),
      end: (0, _moment.default)(new Date(2022, 0, 1, 23, 0)),
      comic_1: {
        name: 'Jason Blanchard',
        src: 'JasonBlanchard.png',
        bio: null
      },
      comic_2: {
        name: 'Lianne Mauladin',
        src: 'LianneMauladin.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2022, 0, 1, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Pierre Brault with MC Colin O\'Brien',
      start: (0, _moment.default)(new Date(2022, 1, 2, 0, 0)),
      end: (0, _moment.default)(new Date(2022, 1, 5, 23, 0)),
      comic_1: {
        name: 'Pierre Brault',
        src: 'PierreBrault.png',
        bio: null
      },
      comic_2: {
        name: 'Colin O`Brien',
        src: 'ColinOBrien.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2022, 1, 6, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner Matt Carter with Kamar Babar',
      start: (0, _moment.default)(new Date(2022, 1, 9, 0, 0)),
      end: (0, _moment.default)(new Date(2022, 1, 12, 23, 0)),
      comic_1: {
        name: 'Matt Carter',
        src: 'MattCarter.png',
        bio: null
      },
      comic_2: {
        name: 'Kamar Babar',
        src: 'KamarBabar.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2022, 1, 6, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner TBA with TBA',
      start: (0, _moment.default)(new Date(2022, 1, 16, 0, 0)),
      end: (0, _moment.default)(new Date(2022, 1, 19, 23, 0)),
      comic_1: {
        name: 'Matt Carter',
        src: 'MattCarter.png',
        bio: null
      },
      comic_2: {
        name: 'Kamar Babar',
        src: 'KamarBabar.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2022, 1, 6, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }, {
      type: 'lineup',
      title: 'Headliner TBA with TBA',
      start: (0, _moment.default)(new Date(2022, 1, 23, 0, 0)),
      end: (0, _moment.default)(new Date(2022, 1, 26, 23, 0)),
      comic_1: {
        name: 'Matt Carter',
        src: 'MattCarter.png',
        bio: null
      },
      comic_2: {
        name: 'Kamar Babar',
        src: 'KamarBabar.png',
        bio: null
      },
      className: (0, _moment.default)(new Date(2022, 1, 6, 23, 0)) <= (0, _moment.default)() ? ['cal_expired'] : ['cal_lineup']
    }],

    lineup: function () {
      var lineup = this.lineups.sort(function (a, b) {
        return b.start - a.start;
      }).reverse().find(function (n) {
        return n.className == 'cal_lineup';
      });
      if (lineup) {
        return {
          date: lineup.start.format('ddd, MMM D') + ' - ' + lineup.end.format('ddd, MMM D'),
          comic_1: lineup.comic_1,
          comic_2: lineup.comic_2
        };
      } else {
        return {
          date: 'Coming Soon',
          comic_1: null,
          comic_2: null
        };
      }
    }.property('lineups')

  });
});