define('absolutecomedy/routes/toronto/checkout', ['exports', 'ember-fetch/ajax', 'lodash', 'moment'], function (exports, _ajax, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    //api/cart/:uid

    model: function model(params) {
      return (0, _ajax.default)('https://app.noqueue.ca/api/cart/' + params.uid, {
        method: 'GET'
      }).then(function (response) {
        return response;
      }).catch(function (err) {
        return null;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (model) {
        console.log(model);
        model.event.show_time = (0, _moment.default)(model.event.show_time).tz("America/Toronto").format('dddd MMMM Do YYYY, h:mm A');
        controller.set('model', model);
      } else {
        this.transitionTo('index');
      }
    }
  });
});