define('absolutecomedy/routes/toronto/pyac', ['exports', 'ember-fetch/ajax', 'lodash', 'moment'], function (exports, _ajax, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return (0, _ajax.default)('https://app.noqueue.ca/api/cms/boxoffice/2/events/pyac', {
        method: 'GET'
      }).then(function (response) {
        return response;
      }).catch(function (err) {
        return [];
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      function formatEvent(n) {
        return {
          id: n.id,
          title: '' + (0, _moment.default)(new Date(n.show_time)).tz("America/Toronto").format('h:mm A'),
          start: (0, _moment.default)(new Date(n.show_time)),
          end: (0, _moment.default)(new Date(n.show_time)),
          name: n.name,
          formatted_date: (0, _moment.default)(new Date(n.show_time)).tz("America/Toronto").format('dddd [-] MMM Do YYYY [@] h:mm A'),
          data: {
            id: n.id,
            formatted_date: (0, _moment.default)(new Date(n.show_time)).tz("America/Toronto").format('dddd [-] MMM Do YYYY [@] h:mm A'),
            boxoffice: n.boxoffice,
            show_time: (0, _moment.default)(n.show_time).tz("America/Toronto").format('MMMM Do YYYY, h:mm A'),
            label: n.name,
            online: n.online,
            skus: n.skus,
            description: n.description,
            sold_out: n.sold_out
          },
          className: ['cal_tickets']
        };
      }

      var events = _lodash.default.map(model, formatEvent);
      events = _lodash.default.sortBy(events, [function (o) {
        return o.start;
      }]);
      console.log(events);
      controller.set('model', events);
    }
  });
});