define('absolutecomedy/components/template-calendar', ['exports', 'ember-fetch/ajax', 'lodash'], function (exports, _ajax, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    stripe: Ember.inject.service('stripev3'),
    mixpanel: Ember.inject.service(),
    router: Ember.inject.service('-routing'),

    didInsertElement: function didInsertElement() {
      return this.get('stripe').load();
    },


    events: [],
    club: null,

    eventModal: false,
    selectedEvent: null,
    number_of_tickets: 2,

    lineupModal: false,
    lineup: [],

    // RESET MODAL WHEN OPEN / CLOSED
    observeModal: function () {
      this.set('number_of_tickets', 2);
    }.observes('eventModal'),

    actions: {
      clickedEvent: function clickedEvent(id) {
        var events = this.get('events');
        var event = _lodash.default.find(events, function (o) {
          return o.id === id;
        });
        if (event) {
          this.set('selectedEvent', event.data);
          this.set('eventModal', true);
          //this.get('mixpanel').trackEvent('Viewed Event', event.data);
        }
      },
      clicked: function clicked(event) {
        if (event.type !== 'lineup') {
          this.set('selectedEvent', event.data);
          this.set('eventModal', true);
          //this.get('mixpanel').trackEvent('Viewed Event', event.data);
        } else {
          if (event.lineup) {
            this.set('lineup', event.lineup);
            this.set('lineupModal', true);
          }
        }
      },
      selectTickets: function selectTickets(quantity) {
        this.set('number_of_tickets', quantity);
      },
      createCart: function createCart(sku) {
        var _this = this;

        var quantity = this.get('number_of_tickets');
        var event = this.get('selectedEvent');

        var payload = {
          event: parseInt(event.id),
          boxoffice: parseInt(event.boxoffice),
          items: [{
            sku: parseInt(sku),
            quantity: parseInt(quantity)
          }]
        };

        (0, _ajax.default)('https://app.noqueue.ca/api/carts', {
          method: 'POST',
          body: JSON.stringify(payload)
        }).then(function (response) {
          _this.get('mixpanel').trackEvent('Created Cart', response);
          _this.get('router').transitionTo(_this.get('club') + '.checkout', [response.uid]);
        }).catch(function (err) {
          //this.get('mixpanel').trackEvent('Cart Error', err);
        });
      }
    }

  });
});