define('absolutecomedy/initializers/mixpanel', ['exports', 'absolutecomedy/config/environment', 'ember-cli-mixpanel-service/configuration'], function (exports, _environment, _configuration) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'mixpanel',
        initialize: function initialize() {
            var config = _environment.default['mixpanel'] || {};
            _configuration.default.load(config);

            var application = arguments[1] || arguments[0];
            application.inject('route', 'mixpanel', 'service:mixpanel');
            application.inject('router:main', 'mixpanel', 'service:mixpanel');
            application.inject('controller', 'mixpanel', 'service:mixpanel');
            application.inject('component', 'mixpanel', 'service:mixpanel');
        }
    };
});