define('absolutecomedy/services/mixpanel', ['exports', 'ember-cli-mixpanel-service/configuration'], function (exports, _configuration) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = Ember.Service.extend({
        pageHasAnalytics: function pageHasAnalytics() {
            return window.mixpanel && _typeof(window.mixpanel) === "object" && _configuration.default.enabled;
        },

        logTrackingEnabled: function logTrackingEnabled() {
            return !!_configuration.default && !!_configuration.default.LOG_EVENT_TRACKING;
        },

        logTracking: function logTracking() {
            Ember.Logger.info('[Mixpanel] ', arguments);
        },

        trackPageView: function trackPageView(page) {
            var overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            if (this.pageHasAnalytics()) {
                if (!page) {
                    var loc = window.location;
                    page = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;
                }

                window.mixpanel.track("visit", Ember.merge({ pageName: page }, overrides));
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('page view', page);
            }
        },

        trackEvent: function trackEvent(event, properties, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.track(event, properties, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking(event, properties, options);
            }
        },

        identify: function identify(userId, traits, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.identify(userId, traits, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('identify user', userId, traits, options);
            }
        },

        alias: function alias(userId, previousId, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.alias(userId, previousId, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('alias user', userId, previousId, options);
            }
        },

        register: function register(traits, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.register(traits, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('register user', traits, options);
            }
        },

        peopleSet: function peopleSet(attributes) {

            if (this.pageHasAnalytics()) {
                window.mixpanel.people.set(attributes);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('people.set', attributes);
            }
        }
    });
});