define('absolutecomedy/components/list-calendar', ['exports', 'ember-fetch/ajax', 'lodash'], function (exports, _ajax, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    stripe: Ember.inject.service('stripev3'),
    mixpanel: Ember.inject.service(),
    router: Ember.inject.service('-routing'),

    didInsertElement: function didInsertElement() {
      return this.get('stripe').load();
    },


    events: [],
    club: null,

    eventModal: false,
    selectedEvent: null,
    number_of_tickets: 2,

    // RESET MODAL WHEN OPEN / CLOSED
    observeModal: function () {
      this.set('number_of_tickets', 2);
    }.observes('eventModal'),

    actions: {
      clickedEvent: function clickedEvent(id) {
        var events = this.get('events');
        var event = _lodash.default.find(events, function (o) {
          return o.id === id;
        });
        if (event) {
          this.set('selectedEvent', event.data);
          this.set('eventModal', true);
          //this.get('mixpanel').trackEvent('Viewed Event', event.data);
        }
      },
      clicked: function clicked(event) {
        if (event.type !== 'lineup') {
          this.set('selectedEvent', event.data);
          this.set('eventModal', true);
          //this.get('mixpanel').trackEvent('Viewed Event', event.data);
        }
      },
      getCookie: function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      selectTickets: function selectTickets(quantity) {
        this.set('number_of_tickets', quantity);
      },
      createCart: function createCart(sku) {
        var _this = this;

        var quantity = this.get('number_of_tickets');
        var event = this.get('selectedEvent');

        var payload = {
          referrer: null,
          event: parseInt(event.id),
          boxoffice: parseInt(event.boxoffice),
          items: [{
            sku: parseInt(sku),
            quantity: parseInt(quantity)
          }]
        };

        (0, _ajax.default)('https://app.noqueue.ca/api/carts', {
          method: 'POST',
          body: JSON.stringify(payload)
        }).then(function (response) {
          _this.get('mixpanel').trackEvent('Created Cart', response);
          _this.get('router').transitionTo(_this.get('club') + '.checkout', [response.uid]);
        }).catch(function (err) {
          //this.get('mixpanel').trackEvent('Cart Error', err);
        });
      }
    }

  });
});