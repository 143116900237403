define('absolutecomedy/components/kingston-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    collapsed: true,

    actions: {
      toggle: function toggle() {
        var state = this.get('collapsed');
        this.set('collapsed', !state);
      }
    }

  });
});