define('absolutecomedy/controllers/toronto/events', ['exports', 'ember-fetch/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    mixpanel: Ember.inject.service(),
    number_of_tickets: 2,

    actions: {
      selectTickets: function selectTickets(quantity) {
        this.set('number_of_tickets', quantity);
      },
      getCookie: function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      createCart: function createCart(sku) {
        var _this = this;

        var quantity = this.get('number_of_tickets');
        var event = this.get('model');

        var payload = {
          referrer: null,
          event: parseInt(event.id),
          boxoffice: parseInt(event.boxoffice),
          items: [{
            sku: parseInt(sku),
            quantity: parseInt(quantity)
          }]
        };

        console.log(payload);

        (0, _ajax.default)('https://app.noqueue.ca/api/carts', {
          method: 'POST',
          body: JSON.stringify(payload)
        }).then(function (response) {
          _this.get('mixpanel').trackEvent('Created Cart', response);
          console.log(response);
          _this.transitionToRoute('toronto.checkout', response.uid);
        }).catch(function (err) {
          //this.get('mixpanel').trackEvent('Cart Error', err);
        });
      }
    }

  });
});