define('absolutecomedy/components/template-checkout', ['exports', 'ember-fetch/ajax', 'lodash'], function (exports, _ajax, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    stripe: Ember.inject.service('stripev3'),
    mixpanel: Ember.inject.service(),

    // CART DETAILS
    cart: null,
    order: null,

    //STRIPE
    options: {
      hidePostalCode: true,
      style: {
        base: {
          color: '#333'
        }
      }
    },

    token: null,

    //ORDER
    fullName: null,
    email: null,
    email_confirm: null,
    phone: null,
    postal_code: null,

    // ERROR MESSAGES
    errors: {
      name: false,
      email: false,
      email_confirm: false,
      phone: false
    },

    disclaimerIsChecked: false,
    canProcess: true,

    OrderConfirmationModal: false,
    orderPayload: {},
    processingOrder: false,

    observeEmail: function () {
      this.verifyEmail();
    }.observes('email'),

    observeEmailConfirm: function () {
      this.verifyEmail();
    }.observes('email_confirm'),

    verifyEmail: function verifyEmail() {
      var email = this.get('email');
      var email_confirm = this.get('email_confirm');
      if (email && email_confirm) {
        if (email !== email_confirm) {
          this.set('errors.email_confirm', true);
        } else {
          this.set('errors.email_confirm', false);
        }
      } else {
        this.set('errors.email_confirm', false);
      }
    },


    actions: {
      updateDisclaimer: function updateDisclaimer() {
        var state = this.get('disclaimerIsChecked');
        this.set('disclaimerIsChecked', !state);
      },
      validateOrder: function validateOrder(stripeElement) {
        var _this = this;

        this.set('canProcess', false);

        var stripe = this.get('stripe');
        var cart = this.get('cart');

        var full_name = this.get('fullName');
        var email = this.get('email');
        var email_confirm = this.get('email_confirm');
        var phone = this.get('phone');
        var postal_code = this.get('postal_code');

        // reset errors
        this.set('errors', {
          name: false,
          email: false,
          email_confirm: false,
          phone: false
        });

        function validateEmail(email) {
          var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        }

        function isValidPhone(p) {
          if (p) {
            var digits = p.replace(/\D/g, '');
            return digits.length === 10 ? true : false;
          } else {
            return false;
          }
        }

        if (full_name && email && validateEmail(email) && phone && isValidPhone(phone) && stripeElement && email === email_confirm) {
          // process order
          stripe.createToken(stripeElement).then(function (_ref) {
            var token = _ref.token,
                error = _ref.error;


            if (token) {

              var payload = {
                payment_method: 'prepaid',
                stripe_token: token.id,
                customer: {
                  full_name: full_name,
                  email: email.trim(),
                  phone: phone,
                  postal: postal_code
                },
                cart: cart.id,
                ip: token.client_ip
              };

              // save
              _this.set('orderPayload', payload);
              _this.set('OrderConfirmationModal', true);
              _this.set('canProcess', true);
            } else {
              _this.set('canProcess', true);
              //this.get('mixpanel').trackEvent('Order Error', error.message);
              alert(error.message);
            }
          }); // end stripe
        } else {
          this.set('canProcess', true);
          //validate errors
          if (!full_name) {
            this.set('errors.name', true);
          } else {
            this.set('errors.name', false);
          }

          if (!email || !validateEmail(email)) {
            this.set('errors.email', true);
          } else {
            this.set('errors.email', false);
          }

          if (!email_confirm || email !== email_confirm) {
            this.set('errors.email_confirm', true);
          } else {
            this.set('errors.email_confirm', false);
          }

          if (!phone || !isValidPhone(phone)) {
            this.set('errors.phone', true);
          } else {
            this.set('errors.phone', false);
          }
        }
      },
      processOrder: function processOrder() {
        var _this2 = this;

        var payload = this.get('orderPayload');
        this.set('processingOrder', true);

        (0, _ajax.default)('https://app.noqueue.ca/api/orders/checkout', {
          method: 'POST',
          body: JSON.stringify(payload)
        }).then(function (order) {
          _this2.set('order', order);
          _this2.set('processingOrder', false);
          _this2.set('OrderConfirmationModal', false);
          _this2.get('mixpanel').trackEvent('Order Complete');
          window.scrollTo(0, 0);
        }).catch(function (err) {
          console.log(err);
          _this2.set('processingOrder', false);
          _this2.set('OrderConfirmationModal', false);
          //this.get('mixpanel').trackEvent('Order Error', 'charge card error');
          alert('Error occured processing your order.');
        });
      }
    }

  });
});