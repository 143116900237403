define('absolutecomedy/components/gift-checkout', ['exports', 'ember-fetch/ajax', 'lodash'], function (exports, _ajax, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    stripe: Ember.inject.service('stripev3'),
    mixpanel: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      return this.get('stripe').load();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var show_only_default = this.get('show_only_default');
      if (show_only_default) {
        this.set('price_per_ticket', show_only_default);
      }
      //this.get('mixpanel').trackEvent('Gift Initiated');
    },


    // CART DETAILS 
    cart: null,
    order: null,

    // Ticket Pricing
    show_only_default: 2000,
    show_only_discount: 1800,

    dinner_show_default: 4300,
    dinner_show_discount: 4000,

    //STRIPE
    options: {
      hidePostalCode: true,
      style: {
        base: {
          color: '#333'
        }
      }
    },

    token: null,

    //ORDER
    fullName: null,
    giftTo: null,
    email: null,
    phone: null,

    // ERROR MESSAGES
    errors: {
      name: null,
      email: null,
      phone: null
    },

    number_of_tickets: 1,
    ticket_type: 'Show Only',
    price_per_ticket: 2000,

    disclaimerIsChecked: false,
    canProcess: true,

    boxoffice: null,

    actions: {
      selectTickets: function selectTickets(quantity) {
        this.set('number_of_tickets', quantity);
        this.send('updateTicketPrice');
      },
      selectTicketType: function selectTicketType(type) {
        this.set('ticket_type', type);
        this.send('updateTicketPrice');
      },
      updateTicketPrice: function updateTicketPrice() {
        var type = this.get('ticket_type');
        var price = this.get('price_per_ticket');
        var number_of_tickets = this.get('number_of_tickets');
        switch (type) {
          case 'Show Only':
            price = number_of_tickets >= 4 ? this.get('show_only_discount') : this.get('show_only_default');
            break;
          case 'Dinner and Show':
            price = number_of_tickets >= 4 ? this.get('dinner_show_discount') : this.get('dinner_show_default');
        }
        this.set('price_per_ticket', price);
      },
      createCart: function createCart() {

        var price = this.get('price_per_ticket');
        var quantity = this.get('number_of_tickets');
        var subtotal = price * quantity;
        var application_fee = Math.ceil(subtotal * 0.031 + 120);
        var stripe_fee = Math.ceil((subtotal + application_fee) * 0.029 + 30);
        var total = subtotal + application_fee + stripe_fee;;

        this.set('cart', [{
          to: this.get('giftTo'),
          type: this.get('ticket_type'),
          price: price,
          discount: 0,
          quantity: quantity,
          application_fee: application_fee,
          stripe_fee: stripe_fee,
          total: total
        }]);

        window.scrollTo(0, 0);
      },
      updateDisclaimer: function updateDisclaimer() {
        var state = this.get('disclaimerIsChecked');
        this.set('disclaimerIsChecked', !state);
      },
      processOrder: function processOrder(stripeElement) {
        var _this = this;

        var stripe = this.get('stripe');
        var cart = this.get('cart');

        var full_name = this.get('fullName');
        var email = this.get('email');
        var phone = this.get('phone');

        // reset errors
        this.set('errors', {
          name: null,
          email: null,
          phone: null
        });

        if (full_name && email && phone && stripeElement) {
          // process order
          stripe.createToken(stripeElement).then(function (_ref) {
            var token = _ref.token;

            _this.set('canProcess', false);

            var payload = {
              account: 1,
              payment_method: 'prepaid',
              stripe_token: token.id,
              expiry_month: 6,
              customer: {
                full_name: full_name,
                email: email,
                phone: phone
              },
              days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
              cart: cart,
              ip: token.client_ip
            };

            (0, _ajax.default)('https://app.noqueue.ca/api/boxoffices/' + _this.get('boxoffice') + '/gifts', {
              method: 'POST',
              body: JSON.stringify(payload)
            }).then(function (order) {
              _this.set('order', order);
              _this.set('canProcess', true);
              //this.get('mixpanel').trackEvent('Gift Success');
              window.scrollTo(0, 0);
            }).catch(function (err) {
              console.log(err);
              alert('Error occured processing your order.');
              _this.set('canProcess', true);
              //this.get('mixpanel').trackEvent('Gift Error', err);
            });
          }); // end stripe
        } else {
          //validate errors
          if (!full_name) {
            this.set('errors.name', 'Required');
          } else {
            this.set('errors.name', null);
          }

          if (!email) {
            this.set('errors.email', 'Required');
          } else {
            this.set('errors.email', null);
          }

          if (!phone) {
            this.set('errors.phone', 'Required');
          } else {
            this.set('errors.phone', null);
          }
        }
      }
    }

  });
});