define('absolutecomedy/routes/kingston/events', ['exports', 'ember-fetch/ajax', 'lodash', 'moment'], function (exports, _ajax, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return (0, _ajax.default)('https://app.noqueue.ca/api/cms/events/' + params.id, {
        method: 'GET'
      }).then(function (response) {
        return response;
      }).catch(function (err) {
        console.log(err);
        return null;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (model) {
        controller.set('model', model);
      } else {
        this.transitionTo('kingston.index');
      }
    }
  });
});