define('absolutecomedy/routes/ottawa/qr-code', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    mixpanel: Ember.inject.service(),
    router: Ember.inject.service('-routing'),

    beforeModel: function beforeModel() {
      this.get('mixpanel').trackEvent('Ottawa QR Code Scanned');
    },
    afterModel: function afterModel() {
      this.get('router').transitionTo('/ottawa');
    }
  });
});