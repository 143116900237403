define('absolutecomedy/controllers/summer-ticket-bonanza', ['exports', 'ember-fetch/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    mixpanel: Ember.inject.service(),
    stripe: Ember.inject.service('stripev3'),

    // CART DETAILS 
    cart: null,
    order: null,

    //STRIPE
    options: {
      hidePostalCode: true,
      style: {
        base: {
          color: '#333'
        }
      }
    },

    token: null,

    //ORDER
    fullName: null,
    giftTo: null,
    email: null,
    phone: null,

    // ERROR MESSAGES
    errors: {
      name: null,
      email: null,
      phone: null
    },

    type: 1, // package
    boxoffice: 1,
    price_per_ticket: 1000,
    number_of_tickets: 2,

    disclaimerIsChecked: false,
    canProcess: true,

    customerNameChanged: Ember.observer('fullName', function () {
      this.send('createCart');
    }),

    actions: {
      selectBoxoffice: function selectBoxoffice(boxoffice) {
        this.set('boxoffice', boxoffice);
      },
      selectPackage: function selectPackage(type) {
        this.set('type', type);
        this.send('updateTickets');
      },
      updateTickets: function updateTickets() {
        var price_per_ticket = this.get('price_per_ticket');
        var number_of_tickets = this.get('number_of_tickets');
        var type = parseInt(this.get('type'));
        switch (type) {
          case 1:
            price_per_ticket = 1000;
            number_of_tickets = 2;
            break;
          case 2:
            price_per_ticket = 950;
            number_of_tickets = 4;
            break;
          case 3:
            price_per_ticket = 900;
            number_of_tickets = 8;
        }
        this.set('price_per_ticket', price_per_ticket);
        this.set('number_of_tickets', number_of_tickets);
        this.send('createCart');
      },
      createCart: function createCart() {

        var price = this.get('price_per_ticket');
        var quantity = this.get('number_of_tickets');
        var subtotal = price * quantity;
        var application_fee = Math.ceil(subtotal * 0.031 + 120);
        var stripe_fee = Math.ceil((subtotal + application_fee) * 0.029 + 30);
        var total = subtotal + application_fee + stripe_fee;;
        this.set('cart', [{
          to: this.get('fullName'),
          type: 'Show Only',
          price: price,
          discount: 0,
          quantity: quantity,
          application_fee: application_fee,
          stripe_fee: stripe_fee,
          total: total
        }]);
      },
      updateDisclaimer: function updateDisclaimer() {
        var state = this.get('disclaimerIsChecked');
        this.set('disclaimerIsChecked', !state);
      },
      processOrder: function processOrder(stripeElement) {
        var _this = this;

        var stripe = this.get('stripe');
        var cart = this.get('cart');

        var full_name = this.get('fullName');
        var email = this.get('email');
        var phone = this.get('phone');

        // reset errors
        this.set('errors', {
          name: null,
          email: null,
          phone: null
        });

        if (full_name && email && phone && stripeElement) {
          // process order
          this.set('canProcess', false);
          stripe.createToken(stripeElement).then(function (_ref) {
            var token = _ref.token;


            var payload = {
              account: 1,
              payment_method: 'prepaid',
              stripe_token: token.id,
              expiry_months: 0,
              customer: {
                full_name: full_name,
                email: email,
                phone: phone
              },
              days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
              cart: cart,
              ip: token.client_ip,
              promo: 'summer-bonanza'
            };

            (0, _ajax.default)('https://app.noqueue.ca/api/boxoffices/' + _this.get('boxoffice') + '/gifts', {
              method: 'POST',
              body: JSON.stringify(payload)
            }).then(function (order) {
              _this.set('order', order);
              _this.set('canProcess', true);
              //this.get('mixpanel').trackEvent('Summer Promo Success');
              window.scrollTo(0, 0);
            }).catch(function (err) {
              console.log(err);
              alert('Error occured processing your order.');
              _this.set('canProcess', true);
              //this.get('mixpanel').trackEvent('Summer Promo Error', err);
            });
          }); // end stripe
        } else {
          //validate errors
          if (!full_name) {
            this.set('errors.name', 'Required');
          } else {
            this.set('errors.name', null);
          }

          if (!email) {
            this.set('errors.email', 'Required');
          } else {
            this.set('errors.email', null);
          }

          if (!phone) {
            this.set('errors.phone', 'Required');
          } else {
            this.set('errors.phone', null);
          }

          this.set('canProcess', true);
        }
      }
    }

  });
});